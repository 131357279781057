<template>
    <!-- Content Wrapper. Contains page content -->
    <div id="content-wrap" class="content-wrapper pt-1">
        <!-- Content Header (Page header) -->
        <!--<section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>{{this.$route.meta.title}}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><router-link to="/"><i class="fa fa-home"></i> 主页</router-link></li>
                            <li class="breadcrumb-item active" v-if="this.$route.name!='Home'">{{this.$route.meta.title}}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>-->
        <section class="content">
            <transition name="page" mode="out-in">
                <div class="row">
                    <div class="col-12">
                        <router-view></router-view>
                    </div>
                </div>
            </transition>
        </section>
        <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'va-content-wrap',
        created() {

        },
        computed: {
            ...mapGetters([
                'pageinfo'
            ])
        }
    }
</script>

<style>
    .page-enter-active, .page-leave-active {
        transition: opacity 0.5s, transform 0.5s;
    }

    .page-enter, .page-leave-to {
        opacity: 0;
    }
</style>
